<template>
  <div class="wrapper ">
    <div class="wrapper__container">
       <jublia-header />
    <div class="container container-transparent">
     
      <div class="container-ins">
        <jublia-h1 class="DINBold text-jublia-bluest mb-6 uppercase">
          {{ $t("user-settings.title") }}
        </jublia-h1>
         <div class="mt-14 w-full flex flex-col justify-center items-center">
         <p class="text-jublia-blue ">{{ $t("user-settings.email") }}</p>
         <p class="text-jublia-blue  h-12 mt-2 px-4 text-xl email mb-6"> {{ (!user)?'XXXX@XXXX':(!user.email)?'XXXX@XXXX':user.email}}</p>
         <p class="text-jublia-blue ">{{ $t("user-settings.phone") }}</p>
         
       
                   <p class="text-jublia-blue  h-12 mt-2 px-4 text-xl email">{{(!user)?'XXX-XXX-XXXX':(!user.phone)?'XXX-XXX-XXXX':user.phone}}</p>

          <jublia-button
          :width="true"
            v-on:click="popclk('op')"
            class="relative mt-2 border-jublia-blue font"
           style="text-transform: uppercase; font-size: 1.8rem;font-weight: 500;"
          >
          <div  style="line-height: 30px !important; " class="mt-2 mb-2">
         {{ $t('user-settings.modfy') }}
         </div>
          </jublia-button>
         </div> 
         <jublia-button
             color="#006699"
             v-on:click="popclk2('op')"
            class="relative m-16 mx-auto border-jublia-blue text-jublia-bluest font pt-2"
           style="  min-width: 75%; width: 67%;font-weight: 500;"
          >
          <div  style="line-height: 30px !important; " class="mt-2 mb-2">
         {{ $t('user-settings.delete') }}
          </div>
          </jublia-button>

           <jublia-button
          :width="true"
           @click="go(`/menu`)"
            class="relative  mx-auto border-jublia-blue font "
           style="text-transform: uppercase; font-size: 1.8rem;"
          >
           <div  style="line-height: 30px !important; " class="mt-2 mb-2">
         {{ $t('user-settings.back') }}
           </div>
          </jublia-button>
      <pop v-if="popup"  v-model:is-open="popup"  @close="close()"  :state="step">
         <template  v-slot:header>
        <jublia-h1  style="margin-bottom: auto" class=" text-center DINBold text-jublia-blue">{{$t('user-settings.titleUpdate')}}</jublia-h1>
        </template>

        <template  v-slot:body>
          <jublia-p class="text" style="text-align: center !important;">{{$t('user-settings.pUpdate1')}}</jublia-p>
          <jublia-p class="text  " style="text-align: center !important;margin-bottom: 0.6rem;">{{$t('user-settings.pUpdate2')}}</jublia-p>
          <input
            v-model="v$.phoneInput.$model" 
            type="text"
            placeholder="xxx-xxx-xxxx"
             v-mask="['###-###-####']"
           class="w-full border border-jublia-blue h-12 rounded-lg mt-2 px-4 text-xl"
           style="margin-bottom: 2rem;"
          />
           <div
          v-if="(submited && error)"
          class="border border-red-500 px-4 py-2 mt-4 text-red-500 text-base text"
        >
         <p class="text"> {{ $t('treatment.reminder.errorPhone') }}</p>
        </div>
         <div
          v-if="(submited)"
          class="border border-red-500 px-4 py-2 mt-4 text-red-500 text-base text"
        >
         <p class="text">  {{$t('reset.error') }}</p>
        </div>
        
          </template>
          <template  v-slot:footer>
           <div class="mt-14 w-full flex  justify-around items-center mb-4">
             <jublia-button
              
            :bdelete="true"
            @click="close();"
            class="relative mt-2 border-jublia-blue  mr-8"
           style="text-transform: uppercase;"
          >
        <div  style="line-height: 30px !important; " class="mt-2 mb-2">
         {{ $t('user-settings.back') }}
         </div>
          </jublia-button>
           <jublia-button
              
            :bdelete="true"
           @click="updatePhone();" 
            class="relative mt-2 border-jublia-blue"
           style="text-transform: uppercase;"
          >
           <div  style="line-height: 30px !important; " class="mt-2 mb-2">
         {{ $t('form-din.valider') }}
          </div>
          </jublia-button>
          </div>
      </template>
      </pop>
      <pop v-if="popup2"  v-model:is-open="popup2"  @close="close()"  :state="step">
         <template  v-slot:header>
        <jublia-h1  style="margin-bottom: auto" class=" text-center DINBold text-jublia-blue">{{$t('user-settings.titleDelete')}}</jublia-h1>
        </template>
        <template  v-slot:body>
           <jublia-p class="text" style="text-align: center !important;">{{$t('user-settings.pUdelete1')}}</jublia-p>
           <jublia-p class="text" style="text-align: center !important; margin-bottom: 2rem;">{{$t('user-settings.pUdelete2')}}</jublia-p>
          <div
          v-if="(submited)"
          class="border border-red-500 px-4 py-2 mt-4 text-red-500 text-base text"
        >
         <p class="text">  {{$t('reset.error') }}</p>
        </div>
          </template>
          <template  v-slot:footer>
             <div class="mt-14 w-full flex  justify-around items-center mb-4">
             <jublia-button
              
            :bdelete="true"
           @click="close();"
            class="relative mt-2 border-jublia-blue   mr-8"
           style="text-transform: uppercase;"
          >
          <div  style="line-height: 30px !important; " class="mt-2 mb-2">
         {{ $t('user-settings.back') }}
          </div>
          </jublia-button>
           <jublia-button
              
            :bdelete="true"
           @click="handleAction('Delete');"
            class="relative mt-2 border-jublia-blue"
           style="text-transform: uppercase;"
          >
           <div  style="line-height: 30px !important; " class="mt-2 mb-2">
         {{ $t('form-din.valider') }}
         </div>
          </jublia-button>
          </div>
             </template>
      </pop>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import JubliaP from "@/components/text/jublia-p";
import JubliaButton from "@/components/button/jublia-button";
import JubliaH1 from "@/components/text/jublia-h1";
import { useI18n } from "vue3-i18n";
import { reactive, ref, computed } from "vue";
import { useRouter } from "vue-router";
import {mapState, mapMutations ,mapActions,mapGetters,useStore } from "vuex";
import JubliaHeader from "@/components/header/jublia-header";

import { mask } from 'vue-the-mask'
//import { mapState, mapMutations ,mapActions,useStore} from 'vuex'
import {maxLength, minLength} from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import axios from 'axios';
  import pop from '@/components/back-pop/back-pop'

export default {
  name: "user",

  components: {
    //JubliaButton,
    pop,
    "jublia-button": JubliaButton,
    "jublia-header": JubliaHeader,

    "jublia-h1": JubliaH1,
    "jublia-p": JubliaP,
  },
directives: {
    mask
  },
  setup() {
      const password=ref("")
       const store= useStore()
      const router  = useRouter()  
      const i18n = useI18n();
       let popup=ref(false)
      let popup2=ref(false)
      const step=ref('')

const popclk=(a)=>{
      step.value=a
     // setTimeout(()=>{
        popup.value=true;
    //  },1000)
  
    console.log(step.value,a)
}
const popclk2=(a)=>{
      step.value=a
     // setTimeout(()=>{
        popup2.value=true;
    //  },1000)
  
    console.log(step.value,a)
}
    return {
       step,
      popup,
      password,
      v$: useVuelidate()
    };
  },
  
   directives: {
    mask
  },
   data: () => ({
    error: false,
    phoneInput: null,
    step:null,
    user: {},
    treatmentProgress: true,
    submited: false,
    popup2:false,
    popup:false,
    isLoading: true,
    displayForm: true,
    v$:null
  
  }),
   
 
  computed: {
    ...mapState({
      url: (state) => state.login.url,
      profil: (state) => state.login.userProfile,
      a: (state) => state.login.token,
      phone: (state) => state.reminder.phone,
      state: (state) => state.login.status,
      reminder: (state) => state.reminder.subscribed
    }),
  ...mapGetters({
     doneCount: 'login/userProfile',
     })
  
  },
  mounted() {
   //console.log( this.profil)
  /* if(!!this.profil){
      if(typeof this.profil==String){
       console.log( this.profil)
      this.user=JSON.parse(sessionStorage.getItem('user'))||{}
       console.log('rrtrt',this.user.email)
    }
    }*/
  },
  created: function () {
   // console.log(typeof this.profil)
    //if(!!this.profil){
   if(!!sessionStorage.getItem('user')){
      // console.log( this.profil)
      this.user= JSON.parse(sessionStorage.getItem('user'))||{}
      // console.log('vb',this.user.email)
   }
   // }
    // this.user.phone=8556554
    this.v$ =useVuelidate();
  },
  methods: {
    
     ...mapActions({
      update: 'login/editUser',
      delete: 'treatment/smallToesInfected'
    }),
  popclk2(a){
      this.step=a
      this.popup2=true;
      //console.log('delete',a)

},
 popclk(a){
      this.step=a
      this.popup=true;
     // console.log('update',a)

},
close(){
       this.error = false
        this.submited = false
         this.popup=false;
          this.popup2=false;
          this.phoneInput=''


},
    handleAction(type) {
      if(this.user.id!=''){
      this.$store.dispatch('login/deleteUser',this.user.id).then(()=>{
           if(this.state!='error'){
            
           this.$router.push(`/din`)
         }
          if(this.state=='error'){
          //console.log('vvvvvvverr')
          this.submited = true
          return null
         // this.user.phone=this.phoneInput
         }
           }).catch((e)=>{
          //console.log('vvvvvvv85')
          this.submited = true
         // this.user.phone=this.phoneInput
        })
           }
    },
     go(a){
     // console.log(a)
   this.$router.push(a)
    },
    updatePhone() {
     
      //this.submited = true
      this.error = false
     // console.log('fgfhghghg',this.phoneInput, this.phoneInput == null,this.v$.phoneInput.$error)
    
       if (this.v$.phoneInput.$error) {
                   this.error = true
                   this.submited = true
         return null}
      
        if (this.phoneInput === null ||this.phoneInput === '') {
           this.submited = true
          this.error = true
          return null
        } else {
                     let ph=this.phoneInput
            //ph.replace(/-/g, '')
                //  ph+''
                           // console.log('vvvvvvv',typeof ph,typeof this.phoneInput)
           this.$store.dispatch('login/editUser',{ email:this.user.email,phone:this.phoneInput}).then(()=>{
          if(this.state!='error'){

         // console.log('vvvvvvv',ph)
          this.user.phone=this.phoneInput
           this.popup=false;
            this.phoneInput=''
         }

          if(this.state=='error'){
          //console.log('vvvvvvv',ph)
          this.submited = true
          return null
         // this.user.phone=this.phoneInput
         }
           })
        
        }
       // this.setPhone(this.phoneInput.replace(/-/g, ''))
      
      /*if (this.treatmentProgress || this.reminder) {
        const datas = {
          reminder: this.reminder,
          treatmentProgress: this.treatmentProgress,
          nextReminder: this.nbDays,
          nbBottles: this.nbBottles,
          phone: this.phone.toString(),
          lang: this.$i18n.getlocale
        }
        //console.log(datas, this.$i18n)
        // this.$router.push(`/0a16e520-f831-11ea-908d-9d6a84f6b7c3/step-photo`)
        try {
          // console.log('fgffgffg')
          const { data } = await axios.post('/user/', datas)
        // sessionStorage.setItem('redirect', data.url)
          this.isLoading = true
          // console.log( data.url)
          this.setUrl(data.url)
         if(data.url==='')
          this.treatmentProgress= false;
           
          if (this.treatmentProgress) {
            this.$router.push(`/${data.url}/step-photo`)
             //this.$router.push(`/0a16e520-f831-11ea-908d-9d6a84f6b7c3/step-photo`)
          }
        } catch (e) {
          this.isLoading = true
          this.error = true
          this.displayForm = true
        }
      }*/
    },
   
    ...mapMutations({
      setSubscribed: 'reminder/setSubscribed',
      setPhone: 'reminder/setPhone',
      setUrl: 'login/setUrl'
    })
  },
   validations () {
  return {  phoneInput: {
      minLength: minLength(12),
      maxLength: maxLength(12)
    },
  }
  }
};
</script>
<style scoped>
.container-ins {
  /* width: 100%;*/
  max-width: 870px;
  margin: 0 auto;
  /* border: 2px solid white;*/
  /* @media all and (min-width: 767px) {
     width: 50%;
   }*/
}
.menu-grid{
    /**display:grid; grid-template-columns: repeat(2, minmax(156px, 1fr)); */
  margin-top: 7rem !important;
   display: grid;
  grid-template-columns: repeat(2,  1fr);
  grid-template-rows: 160px 160px 168px;
  grid-auto-flow: row; 
gap: 28px;
@media all and (min-width: 767px) {
  width: 61%;
margin: auto;
}
}
.item:hover{
transform: scale(1.05);
 transition:  .5s ease;
}
.item{
  /* margin-bottom: 2rem;
                margin: auto;
                 width: 64%;
                
                 text-align: center; */
 /*@media all and (min-width: 767px) {
    min-width: 64%;
   min-height: 179%;
  }*/
  /* @media all and (min-width: 766px) {
    min-width: 64%;
   min-height: 110%;
  }*/
  
  @apply bg-jublia-blue cursor-pointer;
  flex: 1 1 auto;
     width: 166px;
    height: 95%;
    font-size: 12px;
    color: #fff !important;
   /* background-color: #db6525;
    border: 4px solid #00B2AC;*/
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
}
input,::placeholder {
    
  /*  @apply text-gray-500;*/
}
.font {
font-size: 1.8rem;
padding-top: 0.29rem;
padding-bottom: 0rem;
}
input,.email, input[placeholder] {
    text-align: center;
    @apply text-gray-700;
}
.text {
  
    /* font-family: DIN Next LT Pro; */
    /* text-align: justify !important; */
    font-size: 1rem;
    margin-top: .3rem;
    text-align: center !important;
   /* line-height: 90%;*/
    font-weight: 500;
    /* color: #fff !important;
     width: 7rem;*/
}
.container-content {
  @apply w-full;
  @apply m-auto;
  @apply pb-8;
  @apply px-8;
  max-width: 900px;

  @media all and (min-width: 767px) {
    max-width: 500px;
  }
}
</style>
